import React, { useState } from 'react'
import Layout from "../components/layout"
import AchievementBlockItemRedirection from "./blocks/AchievementBlockItemRedirection"
import { Button } from "react-bootstrap"
import SEO from "../components/seo"
import Blocks from "./blocks/Blocks"

const AchievementList = ({ pageContext, location }) => {
    let {
        pages,
        accesDifficile,
        amiante,
        apportDeLumiere,
        desenfumage,
        isolation,
        renovation,
        reparation,
        securisation,
        media,
        allAchievements
    } = pageContext

    let page = pages.filter(page => page.url === "/realisations");
    page = page[0];

    const categories = [
        { id: 'category-1', value: 'Toutes les catégories' },
        { id: 'category-2', value: 'Accès difficile' },
        { id: 'category-3', value: 'Amiante' },
        { id: 'category-4', value: 'Apport de lumière' },
        { id: 'category-5', value: 'Désenfumage' },
        { id: 'category-6', value: 'Isolation' },
        { id: 'category-7', value: 'Rénovation' },
        { id: 'category-8', value: 'Réparation' },
        { id: 'category-9', value: 'Sécurisation' }
    ];

    const [externalLink, setExternalLink] = useState(false)
    const [achievementsToShow, setAchievementsToShow] = useState(allAchievements);
    const [activeButton, setActiveButton] = useState('category-1');

    if(location.state && location.state.category) {
        setExternalLink(true)
    }

    if (externalLink) {
        setAchievementsToShow(getArray(location.state.category))
        setActiveButton(getId(location.state.category))
        location.state.category = null
        setExternalLink(false)
    }

    function getArray(newCategory) {
        switch (newCategory) {
            case 'Accès difficile':
                return accesDifficile
            case 'Amiante':
                return amiante
            case 'Apport de lumière':
                return apportDeLumiere
            case 'Désenfumage':
                return desenfumage
            case 'Isolation':
                return isolation
            case 'Rénovation':
                return renovation
            case 'Réparation':
                return reparation
            case 'Sécurisation':
                return securisation
            default:
                return allAchievements
        }
    }

    function getId(newCategory) {
        const temp = categories.filter(category => category.value === newCategory)
        return temp[0].id
    }

    function changeFilter(newCategory) {
        setAchievementsToShow(getArray(newCategory.value))
        setActiveButton(newCategory.id);
    }

    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={page.titleSEO} />
            <Blocks page={page} media={media} />
            <div className="achievement-list">
                <div className="container">
                    <div className="title-category">
                        <h2>Catégories</h2>
                    </div>
                    <div className="button-list">
                        {categories.map(category => (
                            <Button className="button-category" active={activeButton === category.id} key={category.id} onClick={() => changeFilter(category)}>{category.value}</Button>
                        ))}
                    </div>
                    <div className="row">
                        {achievementsToShow.map(achievement => (
                            <div key={achievement.id} className="col-md-6">
                                <AchievementBlockItemRedirection data={achievement} media={media} balise="H2" baseUrl="/realisations/" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AchievementList